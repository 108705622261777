<template>
    <div class="model">
        <a-spin :spinning="spinning">
            <table class="table">
                <colgroup>
                    <col width='700'>
                    <col width='180'>
                    <col width='160'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th class="th1">问题描述</th>
                        <th>问题分类</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="item  in data" :key="item.Id">
                        <td>
                            <div class="tdtitle dot">{{item.Info}}</div>
                        </td>
                        <td class="center">
                            {{item.Type}}
                        </td>
                        <td class="handle">
                            <div class="handle_inner">
                                <span @click="open(item)">详情</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="data.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="data.length>0"
        />
        <SiteDialog :dialogVisable="visable" :info="dialogData" @close="close"/>
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import SiteDialog from '~/components/Personal/Feedback/SiteDialog'
import { GetMyQuestionList } from '@/api/user'
export default {
    metaInfo() {
		return {
			title: '站点反馈-个人中心-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: '站点反馈-个人中心-文物出版社'
                },
                {
                    name: 'description',
					content: '站点反馈-个人中心-文物出版社'
                }
            ]
		}
	},
    components:{
        Pagination,
        SiteDialog
    },
    data(){
        return{
            data:[],
            params:{
                pageIndex:1,
                pageSize:10
            },
            dialogData:{},
            total:0,
            spinning:false,
            visable:false,
            GetMyQuestionList
        }
    },
    methods:{
        pageChange(){
            this.params.pageIndex = page.page
            this.getList()
            document.getElementsByClassName('personal')[0].scrollIntoView({behavior: "auto"})
        },
        async getList(){
            this.spinning = true
            const { data , total } = await GetMyQuestionList(this.params)
            this.data = data
            this.total = total
            this.spinning = false
        },
        close(){
            this.visable = false
        },
        open(item){
            this.dialogData = item
            this.visable = true
        }
    },
    mounted(){
        this.getList()
    }
}
</script>
<style lang="less" scoped>

:deep(.ant-spin-nested-loading){
    padding: 0 40px;
}
.model{
    .table{
        border-top: 0;
    }
}
.th1{
    text-align: left !important;
    padding-left: 30px;
}
td{
    height: 60px !important;
}
.center{
    text-align: center;
}
.tdtitle{
    position: relative;
    padding-left: 10px;
    &::before{
        content: '';
        width: 2PX;
        height: 2PX;
        background-color: #555555;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}
</style> 